import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import Header from '../header';
import Footer from '../footer';

function Home() {
  let reso = window.innerWidth;
  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
    
  function handleScroll() {
    var element = document.getElementById('animated-element');
    if (isElementInViewport(element)) {
      element.classList.add('fadeInScale');
    } else {
      element.classList.remove('fadeInScale');
    }
  }
  function handleScroll2() {
    var element1Frame3 = document.getElementById('animated-element-frame3-div1');
    var element2Frame3 = document.getElementById('animated-element-frame3-div2');
    var element3Frame3 = document.getElementById('animated-element-frame3-div3');
    var element4Frame3 = document.getElementById('animated-element-frame3-div4');
    var element5Frame3 = document.getElementById('animated-element-frame3-div5');
    var element6Frame3 = document.getElementById('animated-element-frame3-div6');
    if (isElementInViewport(element1Frame3)) {
      element1Frame3.classList.add('fadeInScale');
      element2Frame3.classList.add('fadeInScale');
      element3Frame3.classList.add('fadeInScale');
      element4Frame3.classList.add('fadeInScale');
      element5Frame3.classList.add('fadeInScale');
      element6Frame3.classList.add('fadeInScale');

    } else {
      element1Frame3.classList.remove('fadeInScale');
      element2Frame3.classList.remove('fadeInScale');
      element3Frame3.classList.remove('fadeInScale');
      element4Frame3.classList.remove('fadeInScale');
      element5Frame3.classList.remove('fadeInScale');
      element6Frame3.classList.remove('fadeInScale');


    }
  }
  function handleScroll2Resolucao480() {
    var element1Frame3 = document.getElementById('animated-element-frame3-div1');
    var element2Frame3 = document.getElementById('animated-element-frame3-div2');
    var element3Frame3 = document.getElementById('animated-element-frame3-div3');
   
    if (isElementInViewport(element1Frame3)) {
      element1Frame3.classList.add('fadeInScale');
      element2Frame3.classList.add('fadeInScale');
      element3Frame3.classList.add('fadeInScale');
      
    } else {
      element1Frame3.classList.remove('fadeInScale');
      element2Frame3.classList.remove('fadeInScale');
      element3Frame3.classList.remove('fadeInScale');
      

    }
  }
  function handleScroll3() {
    
    var elementProjects = document.getElementById('animated-element-projects');
    if (isElementInViewport(elementProjects)) {
      elementProjects.classList.add('fadeInSlide');
    } else {
      
    }
  }
  function handleScroll4() {
    
    var elementProducts = document.getElementById('animated-element-products');
    if (isElementInViewport(elementProducts)) {
      elementProducts.classList.add('fadeInSlide');
    } else {
      
    }
  }
  
  
  window.addEventListener('scroll', handleScroll);
  if (reso > 480){
    window.addEventListener('scroll', handleScroll2);
  }else {
    window.addEventListener('scroll', handleScroll2Resolucao480);
  }
  window.addEventListener('scroll', handleScroll3);
  window.addEventListener('scroll', handleScroll4);


  return (
  <>
    
    <link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
  integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
  crossorigin="anonymous"
/>
<link href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;700&display=swap" rel="stylesheet"/>
  <link rel="stylesheet" href="index.css"/>
  <link rel="stylesheet" href="Footer.css"/>
  <link rel="stylesheet" href="Header.css"/>
<Header/>
    {reso <= 480? 
    
    <div className="App">
      <div className="fundo">
        
        
        <h1 className='texto'>Transforme seus<br></br> processos e leve sua<br></br> empresa ao próximo <br></br>nível!!!</h1><br></br>
        <h3 className='texto2'>Potencialize sua produção, reduza despesas e impulsione seus lucros com nossas<br></br> soluções de painéis elétricos e automação </h3>
    
        <div class="container custom-container">
           
        <Carousel controls={false}>
        <Carousel.Item className='custom-row-carousel'>
        <div class="col-md-1 custom-row-miniaturas custom-div div1-img-frame1">
            </div>
            <div class="col-md-1 custom-row-miniaturas-txt">
              <div class="box">Monitoramento <br></br>em tempo real</div>
            </div>
        </Carousel.Item>
        <Carousel.Item className='custom-row-carousel'>
            <div class="col-md-1 mb-1 custom-row-miniaturas custom-div div2-img-frame1">
            </div>
            <div class="col-md-1 custom-row-miniaturas-txt">
              <div class="box">Automação Industrial</div>
            </div>
        </Carousel.Item>
        <Carousel.Item className='custom-row-carousel'>
            <div class="col-md-1 mb-1 custom-row-miniaturas custom-div div6-img-frame3">
            </div>
            <div class="col-md-1 custom-row-miniaturas-txt">
              <div class="box">Painéis elétricos</div>
            </div>
        </Carousel.Item>
      </Carousel>
        </div>
      </div>
    </div>
    : 
    <div className="App">
      <div className="fundo">
        <h1 className='texto'>Transforme seus<br></br> processos e leve sua<br></br> empresa ao próximo <br></br>nível!!!</h1>
        <h3 className='texto2'>Potencialize sua produção, reduza despesas e impulsione seus lucros com nossas<br></br> soluções de painéis elétricos e automação </h3>
        <div class="container custom-container">
        
           
        <Carousel controls={false}>
        <Carousel.Item className='custom-row-carousel'>
        <div class="row">
        <div class="col-md-3 mb-1 custom-row-miniaturas custom-div div1-img-frame1">
            </div>
            <div class="col-md-3 mb-1 custom-row-miniaturas custom-div div2-img-frame1">
            </div>
            <div class="col-md-3 mb-1 custom-row-miniaturas custom-div div6-img-frame3">
            </div>
            </div>
            <div class="row ">
            <div class="col-md-2 custom-row-miniaturas-txt">
              <div class="box ">Monitoramento <br></br>em tempo real</div>
            </div>
            <div class="col-md-2 custom-row-miniaturas-txt">
              <div class="box">Automação Industrial</div>
            </div>
            <div class="col-md-2 custom-row-miniaturas-txt">
              <div class="box">Painéis elétricos</div>
            </div>
          </div>
          
        </Carousel.Item>
        <Carousel.Item className='custom-row-carousel'>
        <div class="row">
        <div class="col-md-3 mb-1 custom-row-miniaturas custom-div div1-img-frame1">
            </div>
            <div class="col-md-3 mb-1 custom-row-miniaturas custom-div div2-img-frame1">
            </div>
            <div class="col-md-3 mb-1 custom-row-miniaturas custom-div div6-img-frame3">
            </div>
            </div>
            <div class="row">
            <div class="col-md-2 custom-row-miniaturas-txt">
              <div class="box">Monitoramento em tempo real</div>
            </div>
            <div class="col-md-2 custom-row-miniaturas-txt">
              <div class="box">Automação Industrial</div>
            </div>
            <div class="col-md-2 custom-row-miniaturas-txt">
              <div class="box">Painéis elétricos</div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
        </div>
      </div>
    </div>
    }

{reso <= 480? 
      <div className='services'>
      <div class="container custom-container-twoo">
         
            <div class="col-md-1 custom-row-frame-two">
              <div class="box custom-title" id="animated-element"><h6>+15 anos<br></br>de experiência<br></br></h6>
              Com uma vasta trajetória na automação industrial e montagem de painéis elétricos, nossa empre​sa destaca-se pela expertise consolidada, garantindo soluções eficientes e inovadoras para seus projetos.</div>
            </div>
            <div class="col-md-1 custom-row-frame-two custom-div-frame-two2 div1-frame-two">
              <div class="box"></div>
            </div>
            
          </div>
         
        </div>
  

      :<div className='services'>
      <div class="container custom-container-two">
          <div class="row">
            <div class="col-md-4 custom-row-frame-two custom-div-frame-two2">
              <div class="box custom-title" id="animated-element"><h1>+15 anos<br></br>de experiência</h1><br></br>
              <h3>Com uma vasta trajetória na automação industrial e montagem de painéis elétricos, nossa empre​sa destaca-se pela expertise consolidada, garantindo soluções eficientes e inovadoras para seus projetos.</h3></div>
            </div>
            <div class="col-md-4 custom-row-frame-two custom-div-frame-two div1-frame-two">
              <div class="box"></div>
            </div>
            
          </div>
         
        </div>
      </div>}

      {reso <= 480? 
      <div className='projects'>
        <div className='div-projects custom-title-projects' id="animated-element-projects"><h1 className='text-projects'>Nossos Projetos</h1></div>
        <div className='div-projects'><h4 className='text-projects2'>Aqui pode ser um breve resumo exemplo - Trabalhamos com tais marcas tais empresas tais metodos...</h4></div>

      <div class="container custom-container-three">
         
            <div class="col-md-1 mb-3 custom-row-frame-three custom-div-frame-two2 div1-img-frame3">
              <div class="box custom-title2" id="animated-element-frame3-div1"><h1>_______________<br></br>testando</h1></div>
            </div>
            <div class="col-md-1 mb-3 custom-row-frame-three custom-div-frame-two2 div2-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div2"><h1>_______________<br></br>testando</h1></div>
            </div>
            <div class="col-md-1 mb-3 custom-row-frame-three custom-div-frame-two2 div3-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div3"><h1>_______________<br></br>testando</h1></div>
            
          </div>
            
         
        </div>
      </div>
      :
      <div className='projects'>
        <div className='div-projects custom-title-projects' id="animated-element-projects"><h1 className='text-projects'>Nossos Projetos</h1></div>
        <div className='div-projects'><h4 className='text-projects2'>Aqui pode ser um breve resumo exemplo - Trabalhamos com tais marcas tais empresas tais metodos...</h4></div>

      <div class="container custom-container-three">
          <div class="row">
            <div class="col-md-3 mb-5 custom-row-frame-three custom-div-frame-two2 div1-img-frame3">
              <div class="box custom-title2" id="animated-element-frame3-div1"><h1>_______________<br></br>testando</h1></div>
            </div>
            <div class="col-md-3 custom-row-frame-three custom-div-frame-two2 div2-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div2"><h1>_______________<br></br>testando</h1></div>
            </div>
            <div class="col-md-3 custom-row-frame-three custom-div-frame-two2 div3-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div3"><h1>_______________<br></br>testando</h1></div>
            </div>
          </div>
          <div class="row custom-row ">
            <div class="col-md-3  custom-row-frame-three2 custom-div-frame-two2 div4-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div4"><h1>_______________<br></br>testando</h1></div>
            </div>
            <div class="col-md-3 custom-row-frame-three2 custom-div-frame-two2 div5-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div5"><h1>_______________<br></br>testando</h1></div>
            </div>
            <div class="col-md-3 custom-row-frame-three2 custom-div-frame-two2 div6-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div6"><h1>_______________<br></br>testando</h1></div>
            </div>
          </div>
         
        </div>
      </div>}

      {reso <= 480? 
      <div className='products'>
        <div className='div-products custom-title-products' id='animated-element-products'><h1 className='text-products'>Nossos Produtos</h1></div>
        <div className='div-products'><h4 className='text-products2'>Aqui pode ser um breve resumo exemplo - Trabalhamos com tais marcas tais empresas tais metodos...</h4></div>

      <div class="container custom-container-three">
            <div class="col-md-3 mb-3 custom-row-frame-three custom-div-frame-two2 div1-img-frame3">
              <div class="box custom-title2" id="animated-element-frame3-div1"><h1>_______________<br></br>testando</h1></div>
            </div>
            <div class="col-md-3 mb-3 custom-row-frame-three custom-div-frame-two2 div2-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div2"><h1>_______________<br></br>testando</h1></div>
            </div>
            <div class="col-md-3 mb-3 custom-row-frame-three custom-div-frame-two2 div3-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div3"><h1>_______________<br></br>testando</h1></div>
            </div>
          </div>
          
         
        </div>
      :
      <div className='products'>
        <div className='div-products custom-title-products' id='animated-element-products'><h1 className='text-products'>Nossos Produtos</h1></div>
        <div className='div-products'><h3 className='text-products2'>Aqui pode ser um breve resumo exemplo - Trabalhamos com tais marcas tais empresas tais metodos...</h3></div>

      <div class="container custom-container-three">
          <div class="row">
            <div class="col-md-3 mb-5 custom-row-frame-three custom-div-frame-two2 div1-img-frame3">
              <div class="box custom-title2" id="animated-element-frame3-div1"><h1>_______________<br></br>testando</h1></div>
            </div>
            <div class="col-md-3 custom-row-frame-three custom-div-frame-two2 div2-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div2"><h1>_______________<br></br>testando</h1></div>
            </div>
            <div class="col-md-3 custom-row-frame-three custom-div-frame-two2 div3-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div3"><h1>_______________<br></br>testando</h1></div>
            </div>
          </div>
          <div class="row custom-row ">
            <div class="col-md-3  custom-row-frame-three2 custom-div-frame-two2 div4-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div4"><h1>_______________<br></br>testando</h1></div>
            </div>
            <div class="col-md-3 custom-row-frame-three2 custom-div-frame-two2 div5-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div5"><h1>_______________<br></br>testando</h1></div>
            </div>
            <div class="col-md-3 custom-row-frame-three2 custom-div-frame-two2 div6-img-frame3">
            <div class="box custom-title2" id="animated-element-frame3-div6"><h1>_______________<br></br>testando</h1></div>
            </div>
          </div>
         
        </div>
      </div>}
      <Footer/>
    </>
  );
}

export default Home;
