import React from 'react';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Empresa from './pages/Empresa';
import Produtos from './pages/Produtos';
import Servicos from './pages/Servicos';
import Contato from './pages/Contato';

function Header() {
  return (
    
    <header className='header-custom'>
     <link href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;700&display=swap" rel="stylesheet"/>
  <link rel="stylesheet" href="index.css"/>
  <link rel="stylesheet" href="Footer.css"/>
  <link rel="stylesheet" href="Header.css"/>
       <div>
      <a href="" ><img src="https://i.ibb.co/vZZT5X4/Logo.png" loading="lazy" alt="" class="logo"></img></a>
      </div>
      <div className='menu'>

      <a href="/" className="menu-item">Início</a>
        <a href="/empresa" className="menu-item">Empresa</a>
        <a href="/produtos" className="menu-item">Produtos</a>
        <a href="/servicos" className="menu-item">Serviços</a>
        <a href="/contato" className="menu-item">Contato</a>


      </div>

    </header>
    
  );
}

export default Header;