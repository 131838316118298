import React from 'react';
import './Footer.css';

function Footer() {

  let reso = window.innerWidth;
  return (
    <>
    {reso <= 480?
    <footer className="footer">
            <p>Alguma frase para o cliente</p>
            <div class="">
                <p>ONDE ESTAMOS</p>
                <p>Av. Nereu Ramos, 3051 E Líder - Chapecó - SC</p>
            </div>
                <p>FALE CONOSCO</p>
            <div class="icones">
                <p className="iconsRedes"><a href="https://api.whatsapp.com/send?phone=5548996967749&amp;text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site"><img src="https://i.ibb.co/1dhb1tj/whatsapp.png" alt="Descrição da Imagem 2" className="whatsApp" /></a></p>
                <p className="iconsRedes"><a href="https://www.instagram.com/scseletricaeautomacao?igsh=eXpkeDN6NTJuOGV4"><img src="https://i.ibb.co/3FJFr5D/instagram.png" alt="Descrição da Imagem 3" className="instagram"/></a></p>
                <p className="iconsRedes"><a href="mailto:contato@scs.com.br?subject=Or%C3%A7amento"><img src="https://i.ibb.co/wpBXGyr/gmail.png" alt="Descrição da Imagem 4" className="email"/></a></p>
                </div>
            <div class="col-md-2 custom-footer ">
            </div>
     

      <div className="divider"></div>
      <p className='coop'>© 2024. Todos os direitos reservados.</p>
    </footer>
    :
    <footer className="footer">
      <p>Alguma frase para o cliente</p>
      <div class="row">
            <div class="col-md-2 custom-footer ">
            </div>
            <div class="col-md-2 custom-footer ">
                <p>ONDE ESTAMOS</p>
                <p>Av. Nereu Ramos, 3051 E Líder - Chapecó - SC</p>
            </div>
            <div class="col-md-2 custom-footer  ">
             
                <p>FALE CONOSCO</p>
                <p className="iconsRedes"><a href="https://api.whatsapp.com/send?phone=5548996967749&amp;text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site"><img src="https://i.ibb.co/1dhb1tj/whatsapp.png" alt="Descrição da Imagem 2" className="whatsApp" /></a></p>
                <p className="iconsRedes"><a href="https://www.instagram.com/scseletricaeautomacao?igsh=eXpkeDN6NTJuOGV4"><img src="https://i.ibb.co/3FJFr5D/instagram.png" alt="Descrição da Imagem 3" className="instagram"/></a></p>
                <p className="iconsRedes"><a href="mailto:contato@scs.com.br?subject=Or%C3%A7amento"><img src="https://i.ibb.co/wpBXGyr/gmail.png" alt="Descrição da Imagem 4" className="email"/></a></p>

                </div>
            
            <div class="col-md-2 custom-footer ">
            </div>
            
          </div>

      <div className="divider"></div>
      <p className='coop'>© 2024. Todos os direitos reservados.</p>
    </footer>}
    </>
  );
}

export default Footer;